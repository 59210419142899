.ReactTable{
  /* border: none !important */
}

.ReactTable .rt-tr{
  justify-content: center;
  align-items: center;
}

.ReactTable .rt-th, .ReactTable .rt-td{
  white-space: pre-wrap !important;
}

.ReactTable .rt-tr-group{
  background-color: white !important;
  margin-top: 5px;
}

.ReactTable .rt-thead .rt-tr{
  text-align: left !important
}

.ReactTable .rt-thead.-header{
  background-color: white;
  box-shadow: none !important;
  font-weight: bold;
}

.ReactTable .rt-noData{
  margin-top: 50px
}