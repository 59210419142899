.chatroom {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  white-space:pre-wrap;
}

.chatroom__header__inforight {
  display: flex;
  align-items: center;
  min-width: 80px;
}

.chatroom__body {
  background-image: url("https://s3.amazonaws.com/emoutech.com.br/files/1/637481255404053598_chat-background.jpg") !important;
  background-repeat: repeat;
  padding: 25px;
  background-position: center;
}

.chatroom__message {
  position: relative;
  font-size: 15px;
  padding: 10px;
  background-color: rgb(243, 240, 240);
  border-radius: 15px;
  width: fit-content;
  min-width: 120px;
  margin-top: 25px;
}

.chatroom__username {
  position: absolute;
  top: -15px;
  font-weight: bold;
  font-size: xx-small;
}

.chatroom__messagetimestamp {
  margin: 8;
  font-weight: bold;
  font-size: xx-small;
  color: gray;
  text-align: left;
}

.chatroom__messagetimestamp_canal_icon{
  position: absolute;
  right: 3px;
  bottom: 3px;
  font-size: 0;
  /* background-color: beige; */
  color: gray;
  border-radius: 20px;
  padding: 2px;
}

.chatroom__messagerecierver {
  margin-left: auto;
  background-color: #c5ff99;
}

.chatroom__messagesystem {
  background-color: #ccc;
  margin-left: auto;
  margin-right: auto;
}

.chatroom__messagesystem {
  text-align: center;
}


/* last div */
.chatroom__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  position: relative;
  border-top: 1px solid rgb(223, 222, 222);
}

/* .chatroom__footer > form > button {
  border: none;
  background-color: #00bfa5;
  color: white;
  padding: 10px;
  border-radius: 50%;
} */

.chatroom__footer > form > input:focus {
  outline-width: 0;
}
.chatroom__footer > form > button:focus {
  outline-width: 0;
}

.chatroom__footer > .MuiSvgIcon-root {
  padding: 10px;
  color: gray;
}
