@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v31/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2)
    format("woff2");
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.pointer {
  cursor: pointer;
}

.padding10 {
  padding: 10px;
}

.padding15 {
  padding: 15px;
}

.margin10 {
  margin: 10px;
}

.full-width {
  width: 100% !important;
}

.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}

.align-center2 {
  display: "flex";
  justify-content: "center";
  flex-wrap: "wrap";
}

.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}

.ifoodColor {
  background-color: brown !important;
  color: white !important;
}

.borderSolid {
  border: solid 1px !important;
}
/*
.TextField  {
  height: 35px;
  margin: 0;
}

.TextField input {
  padding: 8.5px 10px
}

.TextField label {
  margin-top: -10px
} */

.fundodosistema {
  background:url("./assets/img/Emoutech_Fundo3.png");
  background-size: 500px;
  background-position: right center;
  background-position-y: -5px;
  background-repeat: no-repeat;
}

.hideScroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.carousel {
  width: 100%;
}

.carousel-items {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar-track {
  background-color: #1a1a1a;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: #1a1a1a;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
}

.carousel-item {
  flex: none;
  width: 100%;
  height: 400px;
  scroll-snap-align: start;
  pointer-events: none;  
}

.oneLine {   
  overflow: hidden;   
  text-overflow: ellipsis;   
  display: -webkit-box;   
  -webkit-line-clamp: 1;   
  -webkit-box-orient: vertical; 
}

.twoLines {   
  overflow: hidden;   
  text-overflow: ellipsis;   
  display: -webkit-box;
  -webkit-line-clamp: 2;   
  -webkit-box-orient: vertical; 
}

