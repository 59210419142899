@keyframes atencao {
  0% {
    border: 10px solid #FFF;
  }
  50% {
    border: 10px solid #333;
  }
  100% {
    border: 10px solid #000;
  }
}
