.chats {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  border: 1px solid rgb(250, 249, 249);
}

.chats:hover {
  background-color: rgb(236, 233, 233);
}


